import featureImg1 from '../assets/imgs/featureImg1.png';
import featureImg2 from '../assets/imgs/featureImg2.png';
import featureImg3 from '../assets/imgs/featureImg3.png';
import featureImg4 from '../assets/imgs/featureImg4.png';

import solution1 from '../assets/imgs/product1.png';
import solution2 from '../assets/imgs/product2.png';
import solution3 from '../assets/imgs/product3.png';


export const page1 = [
  {
    img: featureImg1,
    title: 'Information System',
    description: 'Provide Internet solutions for government agencies, universities, and corporate businesses, and help various industries to upgrade and transform information.',
  },
  {
    img: featureImg2,
    title: 'APP high-end customization',
    description: 'Professional UI design team and iOS, Android development team, provide a full range of App customizatio development services such as planning, research and development, promotion, operation, after-sales service, etc.',
  },
  {
    img: featureImg3,
    title: 'Big Data',
    description: 'Based on machine learning, it helps companies build a solid data foundation and realize digital operations.',
  },
  {
    img: featureImg4,
    title: 'Network operation and maintenance',
    description: 'Business security prevention and control and data security protection to deal with security risks in the process of informatization.',
  },
];

export const page3 = [
  {
    img: solution1,
    title: 'Tool',
    descriptions: [
      '* Old photo repair, black and white photo coloring',
    ],
    money: [
      'Above $200'
    ]
  },
  {
    img: solution2,
    title: 'System',
    descriptions: [
      '* Establishment of personal and company websites',
      '* Customer service and sales bills system',
    ],
    money: [
      'Above $20000',
      'Above $50000'
    ]
  },
  {
    img: solution3,
    title: 'Market',
    descriptions: [
      '* Web and App promotion in Google and Facebook'
    ],
    money: [
      'Above $8000',
    ]
  },
  
];
export const page4 = [
  {
    img: solution1,
    title: 'Tool',
    descriptions: [
      'Old photo repair, black and white photo coloring',
      '10 old photos repaired',
      '× 1 ',
    ],
    money: [
      '$200'
    ]
  },
  {
    img: solution2,
    title: 'System',
    descriptions: [
      'Establishment of personal and company websites',
      'Company news, company announcements, product atlases, etc',
      '× 1 ',
     ],
    money: [
      '$20000',
     ]
  },
  {
    img: solution2,
    title: 'System',
    descriptions: [
       'Customer service and sales bills system',
       'Server construction, system release and update, and 3-month after-sales service',
       '× 1 ',
    ],
    money: [
       '$50000'
    ]
  },
  {
    img: solution3,
    title: 'Market',
    descriptions: [
      'Web and App promotion in Google and Facebook',
      'A total of three Google advertising placements in January 2024',
      '× 1 ',
    ],
    money: [
      '$8000',
    ]
  },
  
];