import React from 'react';

function Footer(props) {
  return (
    <footer id="footer" className="footer-wrapper">
      <span>Copyright © 2019 SevenInfo All Rights Reserved</span>
    </footer>
  );
}


export default Footer;
