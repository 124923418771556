import React from 'react';
import codeblockBigLogo from '../assets/imgs/codeblockBigLogo.png';


export default function Contact(props) {

  return (
    <>
    <div id="Contact" className={["contact-wrapper", props.isMobile ? "phoneContact" : ""].join(' ')} >
      
      <div className="title">{'Contact Us'}</div>
      <div className="contactDetail">
        <img className="logoImg" src={codeblockBigLogo} alt="logo" />
        <div className="detailInfo">
          <div className="oneRow marginB">
            <svg className="commonIcon">
              <use xlinkHref="#iconcompany"/>
            </svg>
            <div className="detail">
            <div>{'SevenInfo Company'}</div>
            </div>
          </div>
          <div className="oneRow marginB">
            <svg className="commonIcon">
              <use xlinkHref="#iconaddress1"/>
            </svg>
            <div className="detail">
              <div>{'7th floor, BA Lepanto Building, 8747 Paseo de Roxas, Makati City, 1227'}</div>
            </div>
          </div>
          {/* <div className="oneRow marginB">
            <svg className="emailIcon">
              <use xlinkHref="#iconemail"/>
            </svg>
            <div className="detail">{'bm@SevenInfo.ltd'}</div>
          </div> */}
          
        </div>
      </div>
      <div className="footerBg"/>
    </div>
    </>
  );
}
