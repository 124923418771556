import React, { useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page3, page4 } from './data';
import closeIcon from '../assets/imgs/closeIcon.png';
import pay1 from '../assets/imgs/pay1.png';
import pay2 from '../assets/imgs/pay2.png';
import pay3 from '../assets/imgs/pay3.png';
import pay4 from '../assets/imgs/pay4.png';
import pay5 from '../assets/imgs/pay5.png';
import option from '../assets/imgs/option.png';
import select from '../assets/imgs/select.png';

export default function Page3(props) {
  const [buyModal, setBuyModal] = useState(false)
  const [buyNowModal, setBuyNowModal] = useState(false)
  const [oIdx, setOIdx] = useState(-1)
  const showBuy = (flag=false) => {
    props.handleLoginFlag && props.handleLoginFlag(flag)
    // initMask(flag)
    setBuyModal(flag)
   }
   const showBuyNow = (flag=false) => {
      showBuy(false)
    //  initMask(flag)
     setBuyNowModal(flag)
   }
  //  const  initMask =(maskShow=true, scrollTopNo=0) => {
  //   let scrollTop = scrollTopNo
  //   let bodyClass = 'modalOpen'
  //   let classList =  window.document.body.classList
  //   if (maskShow) {
  //     scrollTop = document.scrollingElement.scrollTop  ||
  //     document.documentElement.scrollTop || 
  //     document.body.scrollTop;
  //     document.body.style.top = -scrollTop + 'px';
  //     classList.add(bodyClass)
      
  //   } else {
  //     props.initTop && props.initTop()
  //     classList.remove(bodyClass)
  //     document.scrollingElement.scrollTop = document.documentElement.scrollTop = document.body.scrollTop = scrollTop;
  //   }
  // }
  const renderDesc = (descriptions) => {
    const descHtml= descriptions.map((item, i) => (
      <div key={i.toString() + 'solution'} className={"oneRow" + (i> 0 ? " moreRow":"")}>
        {item}
      </div>
    ));
    return descHtml;
  };
  const children = page3.map((card, i) => (
    <div className="oneSoluton" key={i.toString() + 'c'}>
      <div className="soluInfo" >
        <div className="leftInfo">
          <img src={card.img} alt="img" />
          <div className="solutonDetail">{renderDesc(card.descriptions)}</div>
        </div>
        
        <div className="moneyInfo">
        { card.money.map((item, i) => (
        <div key={i.toString() + 'money'} className={"oneRow" + (i> 0 ? " moreRow":"")}>
          {item}
        </div>
      ))}
      
      </div>
      </div>
      
      <div className="solutonTitle">{card.title}</div>
    </div>
  ));
  const children2 = page4.map((card, i) => (
    <div className="oneSoluton" key={i.toString() + 'c'}>
      <div className="soluInfo" >
        <div className="leftInfo">
          <img src={card.img} alt="img" />
          <div className="solutonDetail">
            <div className="solutonTitle">{card.title}</div>
            <div>{renderDesc(card.descriptions)}</div>
          </div>
        </div>
        
        <div className="moneyInfo">
          
        { card.money.map((item, i) => (
        <div key={i.toString() + 'money'} className={"oneRow" + (i> 0 ? " moreRow":"")}>
          {item}
        </div>
      ))}
      
      </div>
      </div>
      
      
    </div>
  ));

  let phone = localStorage.getItem('phone')
  return (
    <section id="Product"     className={["page-wrapper", props.isMobile ? "phonePage" : ""].join(' ')}>
      <div className="solution-wrapper">
        <div className="title">{'Product'}</div>
        <QueueAnim
            type="bottom"
            className="allSolution"
            key="page3"
            component={Row}
          >
          {children}
        </QueueAnim>
       { !phone && <div className="tip">For more details, contact the staff after you log in</div>}
        {!!phone && <div className="buy" onClick={() => showBuy(true)}>{'Buy now'}</div>}
      </div>
      {
        buyModal && <div key="buyModal" className="buyModal">
         
          <div className="content">
          <div className="close" onClick={() =>showBuy()}>
                <img alt={'close'} src={closeIcon}/>
              </div>
              <div className="info">
                {
                  children2 
                }
              </div>
            {/* <QueueAnim
            type="bottom"
            className="info"
            key="page3"
            component={Row}
          >
          {children2}
        </QueueAnim> */}
        <div className='total'  >
            <div>Total</div>
            <div  className='money' >$78200</div>
              </div>
        <div className='pay' onClick={() => showBuyNow(true)}>
              Pay Now
              </div>
            
          </div>
        </div>
      }
      {
        buyNowModal && <div key="buyNowModal" className="buyModal">
         
          <div className="content">
          <div className="close" onClick={() =>showBuyNow()}>
                <img alt={'close'} src={closeIcon}/>
              </div>
        <div className="payImgs">
      {
        [pay1,pay2,pay3,pay4, pay5].map((item, idx) => <div className='item' onClick={() => setOIdx(idx)}>
         <img  className={[ "option",  idx === oIdx && 'select'].join(' ')} alt={'oIdx'+ idx} src={idx === oIdx  ? select: option} />
         
          <img className="payImg" alt={'pay'+ idx} src={item}/>
        </div>)
      }
       </div>
        <div className='pay' onClick={() => {}}>
              Ok
              </div>
            
          </div>
        </div>
      }
    </section>
  );
}
